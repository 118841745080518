export default [
  {
    header: 'Master',
    action: 'default',
  },
  {
    title: 'TitleMaster.Product',
    route: 'master-product-list',
    icon: 'AwardIcon',
    resource: 'ModuleMaster.Product',
    action: 'read',
  },
  {
    title: 'TitleMaster.Department',
    route: 'master-department-list',
    icon: 'OctagonIcon',
    resource: 'ModuleMaster.Department',
    action: 'read',
  },
  {
    title: 'TitleMaster.FeeType',
    route: 'master-fee-type-list',
    icon: 'HexagonIcon',
    resource: 'ModuleMaster.FeeType',
    action: 'read',
  },
  {
    title: 'TitleMaster.ProductType',
    route: 'master-product-type-list',
    icon: 'ArchiveIcon',
    resource: 'ModuleMaster.ProductType',
    action: 'read',
  },
  {
    title: 'TitleMaster.ProductCategory',
    route: 'master-product-category-list',
    icon: 'PackageIcon',
    resource: 'ModuleMaster.ProductCategory',
    action: 'read',
  },
  {
    title: 'TitleMaster.SpecTemplate',
    route: 'master-spec-template-list',
    icon: 'MenuIcon',
    resource: 'ModuleMaster.SpecTemplate',
    action: 'read',
  },
]
