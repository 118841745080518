export default [
  {
    header: 'Report',
    action: 'default',
  },
  {
    title: 'TitleReport.Booking',
    route: 'report-booking',
    icon: 'CalendarIcon',
    resource: 'ModuleReport.Booking',
    action: 'read',
  },
  {
    title: 'TitleReport.Renting',
    route: 'report-renting',
    icon: 'MapPinIcon',
    resource: 'ModuleReport.Renting',
    action: 'read',
  },
  {
    title: 'TitleReport.Payment',
    route: 'report-payment',
    icon: 'DollarSignIcon',
    resource: 'ModuleReport.Payment',
    action: 'read',
  },
]
